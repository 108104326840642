export const truncateDescription = (description, maxLength = 140) => {
	if (description.length > maxLength) {
	let trimmedString = description.substr(0, maxLength);
		trimmedString = trimmedString.substr(
      0,
      Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
    );
		return `${trimmedString}…`;
	}
	return description
}

export const markupToText = (markup) => {
  let cleanMarkup = document.createElement("textarea");
  cleanMarkup.innerHTML = markup;
  let cleanText = cleanMarkup.value;
  cleanText = cleanText.replace(/(<([^>]+)>)/gi, "");
  cleanText = cleanText.replace(/ View Article/gi, ""); // strip out View Article
  return cleanText;
};


// randomize an array
export const shuffle = (array) => {
  let currentIndex = array.length, temporaryValue, randomIndex;
  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}
